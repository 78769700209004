<template>
    <page :heading="album.name">
        <template v-slot:header-action>
            <button-group>
                <v-button
                    theme="info"
                    :to="{ name: 'dashboard.albums.edit' }"
                >
                    Edit Album
                </v-button>
                <v-button
                    theme="error"
                    @click="deleteAlbum"
                >
                    Delete Album
                </v-button>
            </button-group>
        </template>

        <content-area>
            <gallery :items="album.images" />
        </content-area>

        <gallery-map :album="album" v-if="album.hasMap" />
    </page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import echo from '../../plugins/echo';
import store from '../../store';
import Page from '../../layouts/Page.vue';
import Gallery from '../../components/Presentation/Gallery.vue';
import GalleryMap from '../../components/Presentation/GalleryMap.vue';
import ContentArea from '../../components/Presentation/ContentArea.vue';
import VButton from '../../components/Action/VButton.vue';
import ButtonGroup from '../../components/Presentation/ButtonGroup.vue';

export default {
    components: {
        ButtonGroup,
        VButton,
        ContentArea,
        GalleryMap,
        Gallery,
        Page,
    },
    async beforeRouteEnter({ params }) {
        const { albumId: id } = params;

        await store.dispatch('album/fetchAlbum', { id });

        // Subscribe to album thumbnail channel.  This channel will load in the thumbnails
        // once they are transformed since we're transforming them in a queued task.
        echo.private(`album.${id}.thumbnails`)
            .listen('AlbumThumbnailGenerated', (image) => {
                store.dispatch('album/updateThumbnail', { image });
            });
    },
    computed: {
        ...mapState('album', ['album']),
    },
    methods: {
        ...mapActions('album', {
            deleteAlbumAction: 'deleteAlbum',
        }),
        async deleteAlbum() {
            const confirmed = confirm('Delete this album?'); // eslint-disable-line

            if (confirmed) {
                const { albumId } = this.$route.params;

                const { data: { success } } = await this.deleteAlbumAction({ albumId });

                if (success) {
                    await this.$router.push({ name: 'dashboard.albums' });
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.album-show {
    &__gallery {
        margin-bottom: 2rem;
    }
}
</style>
