<template>
    <div
        class="loading-overlay"
        :class="{ '-is-active': active }"
    >
        <loading-spinner class="loading-overlay__spinner" />
    </div>
</template>

<script>
import LoadingSpinner from './LoadingSpinner.vue';

export default {
    components: { LoadingSpinner },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.loading-overlay {
    @include size(100%);
    @include absolute(0, 0, 0, 0);

    align-items: center;
    background-color: var(--content-area-bg-color);
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: opacity .2s, visibility .3s;
    visibility: hidden;
    z-index: 10;

    &.-is-active {
        opacity: 1;
        visibility: visible;
    }

    &__spinner {
        @include size(5rem);
    }
}
</style>
