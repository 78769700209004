<template>
    <div class="map-wrap">
        <loading-overlay :active="! mapLoaded"/>
        <div id="map" />
    </div>
</template>

<script>
import { _doc } from '@weepower/core/scripts/core/variables';
import { bbox } from '@turf/turf';
import mapboxgl from 'mapbox-gl';
import to from '../../plugins/to';
import api from '../../plugins/api';
import echo from '../../plugins/echo';
import LoadingOverlay from './LoadingOverlay.vue';

mapboxgl.accessToken = 'pk.eyJ1IjoicGV0ZXJiZWRvcmpyIiwiYSI6ImNqdmJwMHlrczAwMm00ZG5temZkdG91NDYifQ.R4NmjKDLYf-x6nVGl_ZSXA'; // TODO

/**
 * Get the bounding box of a feature
 *
 * @param geojson
 * @returns {[number, number, number, number]|[[*, *], [*, *]]}
 */
const getBoundingBox = (geojson) => {
    if (geojson.features.length) {
        const bounds = bbox(geojson);

        return [[bounds[0], bounds[1]], [bounds[2], bounds[3]]];
    }

    return [-125.0011, 24.9493, -66.9326, 49.5904];
};

export default {
    name: 'GalleryMap',
    components: { LoadingOverlay },
    props: {
        album: {
            type: Object,
            default: () => ({}),
        },
    },
    created() {
        this.map = null;
    },
    async mounted() {
        await this.createMap();

        this.map.resize();

        const [err, res] = await to(api.get(`albums/${this.album.id}/geojson`));

        if (!err) {
            const { data } = res;

            // Add map markers
            data.features.forEach(this.addMarker.bind(this));

            // Fit the map to the bounding box of the current feature collection
            this.map.fitBounds(getBoundingBox(data), { padding: 50, linear: false, duration: 0 });
        }

        echo.private(`album.${this.album.id}.thumbnails`)
            .listen('AlbumThumbnailGenerated', (image) => {
                const imageEl = _doc.querySelector(`#image-${image.id}`);

                imageEl.src = image.thumbnail;
                imageEl.parentElement.style.display = 'block';
            });

        this.map.on('idle', () => {
            this.mapLoaded = true;
        });
    },
    methods: {
        addMarker(feature) {
            const el = _doc.createElement('div');
            el.className = 'marker';
            el.style.width = '60px';
            el.style.height = '60px';

            const img = _doc.createElement('img');
            img.src = feature.properties.thumbnail;
            img.id = `image-${feature.properties.id}`;

            if (!feature.properties.thumbnail) {
                el.style.display = 'none';
            }

            el.appendChild(img);

            // When a user clicks on a thumbnail on the map, find the highest z-index
            // for every map thumbnail and set the target's z-index to that plus one and
            // then pan to it.
            el.addEventListener('click', ({ target }) => {
                let highestZIndex = 0;

                this.map.panTo(feature.geometry.coordinates);

                _doc.querySelectorAll('.marker').forEach((marker) => {
                    const zIndex = parseInt(marker.style.zIndex, 10);

                    if (zIndex > highestZIndex) {
                        highestZIndex = zIndex;
                    }
                });

                target.style.zIndex = Number(highestZIndex) + 1;
            });

            new mapboxgl.Marker(el)
                .setLngLat(feature.geometry.coordinates)
                .addTo(this.map);
        },
        async createMap() {
            return new Promise(((resolve) => {
                this.map = new mapboxgl.Map({
                    container: 'map',
                    style: 'mapbox://styles/peterbedorjr/cklaf9q6i036u17pfojwp54v9',
                    center: [-74.5, 40], // starting position [lng, lat]
                    zoom: 9,
                });

                this.map.on('load', resolve);
            }));
        },
    },
    data: () => ({
        mapLoaded: false,
    }),
};
</script>

<style lang="scss">
@import '~mapbox-gl/dist/mapbox-gl.css';

.map-wrap {
    border-radius: var(--border-radius);
    overflow: hidden;
    position: relative;
}

#map {
    height: 30rem;
    width: 100%;
}

.marker {
    padding: .5rem;
    border-radius: $defaultRadius;
    background-color: $white;
    cursor: pointer;
    top: -3rem;
    box-shadow: var(--map-marker-thumbnail-box-shadow);

    &::after {
        content: '';
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #fff;
        transform: translate(68%, -.1rem);
        display: block;
    }

    img {
        pointer-events: none;
    }
}
</style>
