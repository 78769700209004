<template>
    <div
        class="button-group"
        :class="classes"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'ButtonGroup',
    props: {
        size: {
            type: String,
            default: 'normal',
        },
    },
    computed: {
        classes() {
            return [
                `-${this.size}`,
            ];
        },
    },
};
</script>

<style lang="scss">
.button-group {
    display: flex;

    > .button {
        &:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &:not(:first-child):not(:last-child) {
            border-radius: 0;
        }
    }

    &.-small {
        > .button {
            padding: .5rem 1rem;
            font-size: 1.2rem;
        }
    }
}
</style>
